* {
  margin: 0;
  padding: 0;
}

:root {
  --navmenu-bg: #1e1e1e;
  --navmenu-text: #6d6e71;
  --navmenu-link-color: #f37b54;
  --white-bg-text: #6d6e71;
  --work-bg-text: #808184;
  --work-bold-text: #bbbdbf;
  --welcome-bg-text-color: #f37b54;
  --welcome-bg-text-normal: #6d6e71;
  --contact-bg-color: #1e1e1e;
  --contact-subtitle-color: #929497;
  --contact-subtitle-color-dark: #6d6e71;
  --about-us-bg: #1e1e1e;
  --about-us-colored: #f37b54;
  --about-us-normal: #6d6e71;
  --about-us-service-title: #a6a8ab;
  --about-us-service-subtitle: #6d6e71;
}
