.contact {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: var(--contact-bg-color);
}

.contact-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.contact-image-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.contact-image {
  width: 38.3vw;
  object-fit: contain;
  max-height: 50vh;
}

.contact-subdetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -5.6vh;
}

.contact-subtitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-info {
  color: var(--contact-subtitle-color);
  font-family: "Saira Extra Condensed";
  font-weight: 300;
  word-wrap: break-word;
  display: flex;
  word-break: keep-all;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0px;
  line-height: 3.6vw;
  font-size: 3.22vw;
  white-space: pre-wrap;
}

.contact-address {
  color: var(--contact-subtitle-color-dark);
  font-family: "Saira Extra Condensed";
  font-weight: 300;
  word-wrap: break-word;
  display: flex;
  word-break: keep-all;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0px;
  line-height: 3.29vw;
  font-size: 1.46vw;
}

.contact-social {
  margin-top: clamp(1rem, 3.9vh, 1rem);
}
.contact-social > a {
  margin-right: 1vw;
}

.contact-social > a > img {
  width: 4.5vw;
  max-width: 50px;
}

.contact-designed {
  color: var(--contact-subtitle-color-dark);
  font-family: "Saira Extra Condensed";
  font-weight: 300;
  word-wrap: break-word;
  display: flex;
  word-break: keep-all;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0px;
  line-height: 1;
  font-size: 1.46vw;
  position: absolute;
  bottom: 0;
  white-space: pre-wrap;
}

.contact-designed > p > a {
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  white-space: pre-wrap;
  color: var(--contact-subtitle-color-dark);
}

.contact-designed > p > a:hover {
  color: var(--navmenu-link-color);
}

@media only screen and (max-width: 767.98px) {
  .contact-image {
    width: 83.75vw;
    object-fit: contain;
  }

  .contact-subdetails {
    margin-top: clamp(-0.8rem, -3.5vh, -0.5rem);
    max-width: 100%;
  }

  .contact-info {
    line-height: clamp(5vw, 7.2vw, 5rem);
    font-size: clamp(5vw, 7.2vw, 5rem);
  }

  .contact-address {
    line-height: clamp(4vw, 4vw, 1.5rem);
    font-size: clamp(4vw, 4vw, 1.5rem);
    margin: clamp(1rem, 3vh, 1.5rem) 0;
  }

  .contact-social {
    margin-top: clamp(0.2rem, 3vh, 0.4rem) 0;
  }
  .contact-social > a {
    margin-right: min(3vw, 9px);
  }

  .contact-social > a > img {
    width: 11.5vw;
    max-width: 50px;
  }

  .contact-designed {
    line-height: 1;
    font-size: clamp(4vw, 4vw, 1.5rem);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1365.98px) {
  .contact-image {
    width: 83.75vw;
    object-fit: contain;
  }

  .contact-subdetails {
    margin-top: -3.5vh;
  }

  .contact-info {
    line-height: clamp(2rem, 6.8vw, 3rem);
    font-size: clamp(2rem, 6.8vw, 3rem);
  }

  .contact-address {
    line-height: clamp(0.5rem, 4vw, 1rem);
    font-size: clamp(0.5rem, 4vw, 1.5rem);
    margin: clamp(1rem, 3vh, 1.5rem) 0;
  }

  .contact-social {
    margin-top: clamp(1rem, 3vh, 1.5rem);
  }
  .contact-social > a {
    margin-right: min(3vw, 9px);
  }

  .contact-social > a > img {
    width: 11.5vw;
    max-width: 60px;
  }

  .contact-designed {
    line-height: 1;
    font-size: clamp(0.5rem, 4vw, 1.5rem);
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1919.97px) {
  .contact-image {
    width: 36.3vw;
    object-fit: contain;
  }

  .contact-subdetails {
    margin-top: -4vh;
  }

  .contact-info {
    line-height: 3.4vw;
    font-size: 3vw;
  }

  .contact-address {
    line-height: 3.12vw;
    font-size: 1.38vw;
  }

  .contact-social {
    margin-top: 2.7vh;
  }
  .contact-social > a {
    margin-right: 1vw;
  }

  .contact-social > a > img {
    width: 4.5vw;
  }
  .contact-designed {
    line-height: 1;
    font-size: 1.38vw;
  }
}

@media only screen and (min-width: 1920px) {
  .contact-image {
    width: 34.7vw;
    object-fit: contain;
  }

  .contact-subdetails {
    margin-top: -4vh;
  }

  .contact-info {
    line-height: 3.4vw;
    font-size: 3vw;
  }

  .contact-address {
    line-height: 3.12vw;
    font-size: 1.38vw;
  }

  .contact-social {
    margin-top: clamp(1rem, 3.9vh, 1rem);
  }
  .contact-social > a {
    margin-right: 1vw;
  }

  .contact-social > a > img {
    width: 4.5vw;
  }

  .contact-designed {
    line-height: 1;
    font-size: 1.38vw;
  }
}

/* @media (max-width: 768px) {
  .contact-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .contact-image {
    width: 268px;
    height: 150px;
  }

  .contact-subdetails {
    max-width: 248px;
    margin-top: -30px;
  }

  .contact-subtitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contact-info {
    line-height: 23px;
    font-size: 23px;
  }

  .contact-address {
    line-height: 46px;
    font-size: 13px;
  }

  .contact-social {
    margin-top: 20px;
  }
  .contact-social > a {
    margin-right: 8px;
  }
} */
