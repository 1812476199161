.navbar {
  position: absolute;
  top: 0;
  padding: 0;
  padding-top: 7.29vh;
  padding-left: 9vw;
  padding-right: 9vw;
  width: 100vw;
  z-index: 100;
  background: transparent;
}

.container-logo {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.navbar-logo > img {
  width: 8.33vw;
}

.navbar-toggler {
  border: none !important;
}

.navbar-toggle-btn {
  cursor: pointer;
  width: 2.2vw;
}

.navbar-close {
  cursor: pointer;
  width: 4.98vw;
}

.navbar-close > img {
  width: 100%;
}

.nav-menu {
  position: fixed;
  background-color: var(--navmenu-bg);
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.nav-menuList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-menuList a {
  text-decoration: none;
  color: var(--navmenu-text);
  font-family: "Saira Extra Condensed";
  font-weight: 100;
  font-size: 6.73vw;
  line-height: 6.73vw;
  transition: all 0.2s ease-in-out;
}

.nav-menuList > a:hover {
  color: var(--navmenu-link-color);
}

.nav-menu-footer {
  position: absolute;
  bottom: 0;
}

.nav-menu-footer > p {
  padding: 0;
  line-height: 1;
  font-size: 1.46vw;
  margin-bottom: 4.68vh;
  color: var(--navmenu-text);
  text-transform: uppercase;
  text-align: center;
  white-space: pre-wrap;
}

.nav-menu-footer > p > a {
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  white-space: pre-wrap;
  color: var(--navmenu-text);
}

.nav-menu-footer > p > a:hover {
  color: var(--navmenu-link-color);
}

@media only screen and (max-width: 767.98px) {
  .navbar {
    padding: 0;
    padding-top: 4.7vh;
    padding-left: 8.75vw;
    padding-right: 8.75vw;
  }
  .navbar-logo > img {
    width: 21.5vw;
    max-width: 114px;
  }
  .navbar-toggle-btn {
    width: 6.25vw;
    max-width: 30px;
  }
  .navbar-close {
    width: 11.56vw;
    max-width: 68px;
  }
  .nav-menuList a {
    font-size: 12.5vw;
    line-height: 13vw;
  }

  .nav-menu-footer > p {
    padding: 0;
    font-size: clamp(1rem, 4vw, 1.25rem);
    line-height: 1;
    margin-bottom: 4.68vh;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1365.98px) {
  .navbar {
    padding: 0;
    padding-top: 4.7vh;
    padding-left: 8.75vw;
    padding-right: 8.75vw;
  }
  .navbar-logo > img {
    width: 21.5vw;
    max-width: 114px;
  }
  .navbar-toggle-btn {
    width: 6.25vw;
    max-width: 30px;
  }
  .navbar-close {
    width: 11.56vw;
    max-width: 68px;
  }
  .nav-menuList a {
    font-size: clamp(20px, 12.5vw, 5rem);
    line-height: clamp(20px, 13vw, 5rem);
  }

  .nav-menu-footer > p {
    padding: 0;
    line-height: 1;
    font-size: clamp(1rem, 4vw, 1.25rem);
    margin-bottom: 4.68vh;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1919.97px) {
  .navbar {
    padding: 0;
    padding-top: 5.9vh;
    padding-left: 9.38vw;
    padding-right: 9.38vw;
  }
  .navbar-logo > img {
    width: 8.33vw;
  }
  .navbar-toggle-btn {
    width: 2.2vw;
  }
  .navbar-close {
    width: 4.7vw;
  }
  .nav-menuList a {
    font-size: 6.38vw;
    line-height: 6.38vw;
  }

  .nav-menu-footer > p {
    padding: 0;
    line-height: 1;
    font-size: clamp(1.5rem, 4vw, 1.8rem);
    margin-bottom: 4.68vh;
  }
}

@media only screen and (min-width: 1920px) {
  .navbar {
    padding: 0;
    padding-top: 6.79vh;
    padding-left: 8.95vw;
    padding-right: 8.95vw;
  }
  .navbar-logo > img {
    width: 7.18vw;
  }
  .navbar-toggle-btn {
    width: 1.56vw;
  }
  .navbar-close {
    width: 4.97vw;
  }
  .nav-menuList a {
    font-size: 6.72vw;
    line-height: 6.72vw;
  }

  .nav-menu-footer > p {
    padding: 0;
    line-height: 1;
    font-size: 1.38vw;
    margin-bottom: 4.68vh;
  }
}

/* @media (max-width: 756px) {
  .nav-menuList a {
    text-decoration: none;
    color: var(--navmenu-text);
    font-family: "Saira Extra Condensed";
    font-size: 40px;
    font-weight: 100;
    line-height: 40px;
  }

  .navbar-close {
    width: 37px;
  }

  .nav-menu-footer {
    display: flex;
    align-self: auto;
    margin-bottom: 30px;
  }
  .nav-menu-footer > p {
    font-size: 13pt;
    line-height: 13pt;
  }
} */
