.welcome {
  width: 100vw;
  height: 100vh;
  position: absolute;
}
.welcome-image-logo {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.welcome-container {
  height: 100%;
  padding: 15vh 25.5vw;
  display: flex;
}

.welcome-tilt {
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(0, 0, 0);
}

.tilt-container {
  width: 100%;
  height: 100%;
}

.logo-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.inner-element {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.logo-typo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cont {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.welcome-typography {
  max-width: 43.9vw;
  position: absolute;
  height: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  margin-top: -16.3vh;
}

.welcome-subtitle {
  font-family: "Saira Extra Condensed";
  font-weight: 300;
  word-wrap: break-word;
  word-break: keep-all;
  text-transform: uppercase;
  text-align: center;
  color: var(--welcome-bg-text-color);
  letter-spacing: 0px;
  font-size: 2.04vw;
  line-height: 2.7vw;
  font-size: clamp(1rem, 6.25vw, 1.5rem);
  line-height: clamp(1rem, 6.8vw, 1.75rem);
}

.welcome-title {
  display: flex;
  flex-direction: column;
  margin-top: 8vh;
}

.welcome-title-small {
  font-family: "Saira Extra Condensed";
  font-weight: 300;
  text-transform: uppercase;
  color: var(--welcome-bg-text-normal);
  letter-spacing: 0px;
  text-align: center;
  font-size: 1.94vw;
  line-height: 2.56vw;
  font-size: clamp(1rem, 6.25vw, 1.5rem);
  line-height: clamp(1rem, 6.8vw, 1.65rem);
}

.welcome-title-large {
  font-family: "Saira Extra Condensed";
  font-weight: bold;
  text-transform: uppercase;
  color: var(--welcome-bg-text-normal);
  letter-spacing: 0px;
  font-size: 4.24vw;
  line-height: 3.44vw;
  font-size: clamp(1.5rem, 11.25vw, 3.5rem);
  line-height: clamp(1.3rem, 10vw, 2.7rem);
  text-align: center;
  white-space: pre-wrap;
}

.scroll-icon {
  position: absolute;
  width: 2.5vw;
  bottom: 7.29vh;
  width: 2rem;
  bottom: 3rem;
  left: 50%;
}

@media only screen and (max-width: 767.98px) {
  .welcome-container {
    height: 100%;
    padding: 12vh 15vw;
    display: flex;
  }

  .welcome-typography {
    max-width: 65.75vw;
    margin-top: -15vh;
  }

  .welcome-subtitle {
    font-size: 6.5vw;
    line-height: 7vw;
    font-size: clamp(1rem, 6.25vw, 1.25rem);
    line-height: clamp(1rem, 6.8vw, 1.5rem);
  }

  .welcome-title {
    display: flex;
    flex-direction: column;
    margin-top: 7.5vh;
  }

  .welcome-title-small {
    font-size: 6.5vw;
    line-height: 7vw;
    font-size: clamp(1rem, 6.25vw, 1.25rem);
    line-height: clamp(1rem, 6.8vw, 1.35rem);
  }

  .welcome-title-large {
    font-size: 11.25vw;
    line-height: 9.4vw;
    font-size: clamp(1.5rem, 11.25vw, 3.25rem);
    line-height: clamp(1.3rem, 9vw, 2.45rem);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1365.98px) {
  .welcome-container {
    height: 100%;
    padding: 12vh 15vw;
    display: flex;
  }

  .welcome-typography {
    max-width: 65.75vw;
  }

  .welcome-subtitle {
    line-height: 5vw;
    font-size: 5vw;
    font-size: clamp(1rem, 6.25vw, 1.5rem);
    line-height: clamp(1rem, 6.8vw, 1.75rem);
  }

  .welcome-title {
    display: flex;
    flex-direction: column;
    margin-top: 8.125vh;
  }

  .welcome-title-small {
    line-height: 5vw;
    font-size: 5vw;
    font-size: clamp(1rem, 6.25vw, 1.5rem);
    line-height: clamp(1rem, 6.8vw, 1.65rem);
  }

  .welcome-title-large {
    font-size: 10.25vw;
    line-height: 8vw;
    font-size: clamp(1.5rem, 11.25vw, 3.5rem);
    line-height: clamp(1.3rem, 10vw, 2.7rem);
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1919.97px) {
  .welcome-container {
    height: 100%;
    padding: 11vh 27vw;
    display: flex;
  }

  .welcome-typography {
    max-width: 41.5vw;
  }

  .welcome-subtitle {
    line-height: 2.4vw;
    font-size: 1.94vw;
    font-size: clamp(1rem, 6.25vw, 2rem);
    line-height: clamp(1rem, 6.8vw, 2.25rem);
  }

  .welcome-title {
    display: flex;
    flex-direction: column;
  }

  .welcome-title-small {
    line-height: 2.4vw;
    font-size: 1.94vw;
    font-size: clamp(1rem, 6.25vw, 2rem);
    line-height: clamp(1rem, 6.8vw, 2.25rem);
  }

  .welcome-title-large {
    font-size: 4vw;
    line-height: 3.26vw;
    font-size: clamp(1.5rem, 11.25vw, 3.75rem);
    line-height: clamp(1.3rem, 10vw, 3rem);
  }
}

@media only screen and (min-width: 1920px) {
  .welcome-container {
    height: 100%;
    padding: 13.8vh 28.5vw;
    display: flex;
  }

  .welcome-typography {
    max-width: 43vw;
    max-width: clamp(1rem, 43vw, 39rem);
    margin-top: -15vh;
  }

  .welcome-subtitle {
    font-size: 1.94vw;
    line-height: 2.7vw;
    line-height: clamp(1rem, 2.4vw, 3rem);
    font-size: clamp(1rem, 1.94vw, 2.5rem);
  }

  .welcome-title {
    display: flex;
    flex-direction: column;
    margin-top: 7vh;
  }

  .welcome-title-small {
    font-size: 2vw;
    line-height: 2vw;
    line-height: clamp(1.5rem, 2.4vw, 2.7rem);
    font-size: clamp(1.5rem, 1.94vw, 3rem);
  }

  .welcome-title-large {
    font-size: 4.27vw;
    line-height: 3.43vw;
    font-size: clamp(2.5rem, 4.27vw, 5rem);
    line-height: clamp(1.75rem, 3.43vw, 3.75rem);
  }
}
