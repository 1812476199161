.home {
  width: 100vw;
  height: 100vh;
  position: absolute;
}

.home-tilt {
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(0, 0, 0);
}

.home-container {
  height: 100%;
  padding: 15vh 11vw;
  display: flex;
}

.inner-element {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.tilt-container {
  width: 100%;
  height: 100%;
}

.logo-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.home-image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.home-image-logo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-title {
  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cont {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.home-title-subtitle {
  margin-bottom: 0;
  font-family: "Saira Extra Condensed";
  font-weight: 300;
  letter-spacing: 0;
  font-size: 1.94vw;
  line-height: 2.56vw;
  font-size: clamp(1rem, 6.25vw, 1.5rem);
  line-height: clamp(1rem, 6.8vw, 1.65rem);
  color: var(--white-bg-text);
  text-transform: uppercase;
}

.scroll-icon {
  position: absolute;
  width: 2.5vw;
  bottom: 7.29vh;
  width: 2rem;
  bottom: 3rem;
  left: 50%;
}

.scroll-icon > img {
  width: 100%;
  object-fit: contain;
}

.home-title-bold {
  margin-bottom: 0;
  font-family: "Saira Extra Condensed";
  font-weight: bold;
  font-size: 4.24vw;
  line-height: 3.44vw;
  font-size: clamp(1.5rem, 11.25vw, 3.5rem);
  line-height: clamp(1.3rem, 10vw, 2.7rem);
  letter-spacing: 0px;
  color: var(--white-bg-text);
  text-transform: uppercase;
  white-space: pre-wrap;
}

@media only screen and (max-width: 767.98px) {
  .home-container {
    height: 100%;
    padding: 12vh 15vw;
    display: flex;
  }

  .home-title-subtitle {
    font-size: 6.25vw;
    line-height: 6vw;
    font-size: clamp(1rem, 6.25vw, 1.25rem);
    line-height: clamp(1rem, 6.8vw, 1.35rem);
  }

  .home-title-bold {
    font-size: 11.25vw;
    line-height: 9vw;
    font-size: clamp(1.5rem, 11.25vw, 3.25rem);
    line-height: clamp(1.3rem, 9vw, 2.45rem);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1365.98px) {
  .home-container {
    height: 100%;
    padding: 12vh 15vw;
    display: flex;
  }

  .home-title-subtitle {
    line-height: 5vw;
    font-size: 5vw;
    font-size: clamp(1rem, 6.25vw, 1.5rem);
    line-height: clamp(1rem, 6.8vw, 1.65rem);
  }

  .home-title-bold {
    font-size: 10.25vw;
    line-height: 8vw;
    font-size: clamp(1.5rem, 11.25vw, 3.5rem);
    line-height: clamp(1.3rem, 10vw, 2.7rem);
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1919.97px) {
  .home-container {
    height: 100%;
    padding: 11vh 27vw;
    display: flex;
  }

  .home-title-subtitle {
    line-height: 2.4vw;
    font-size: 1.94vw;
    font-size: clamp(1rem, 6.25vw, 2rem);
    line-height: clamp(1rem, 6.8vw, 2.25rem);
  }

  .home-title-bold {
    font-size: 4vw;
    line-height: 3.26vw;
    font-size: clamp(1.5rem, 11.25vw, 3.75rem);
    line-height: clamp(1.3rem, 10vw, 3rem);
  }
}

@media only screen and (min-width: 1920px) {
  .home-container {
    height: 100%;
    padding: 13.8vh 28.5vw;
    display: flex;
  }
  .home-title-subtitle {
    font-size: 2vw;
    line-height: 2vw;
    line-height: clamp(1.5rem, 2.4vw, 2.7rem);
    font-size: clamp(1.5rem, 1.94vw, 3rem);
  }

  .home-title-bold {
    font-size: 4.27vw;
    line-height: 3.43vw;
    font-size: clamp(2.5rem, 4.27vw, 5rem);
    line-height: clamp(1.75rem, 3.43vw, 3.75rem);
  }
}
